/* Personalization Page Specific Styles */
.personalisation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff; /* Assuming white is your app's theme color */
}

.personalisation-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
}

.personalisation-form {
  width: 100%;
  max-width: 500px; /* Ensuring it doesn't stretch too much on larger screens */
}

.personalisation-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.personalisation-select, .personalisation-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 20px;
  border-radius: 20px; /* Rounded corners */
  border: 1px solid #d3d3d3; /* Subtle border color */
  font-size: 16px; /* Accessibility */
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  box-sizing: border-box;
}

.personalisation-textarea {
  height: 120px;
  resize: vertical; /* Disable resize as it may break your layout */
}

.return-to-camera-button {
  background-color: #000000; /* Black as per your app's button color */
  color: #ffffff; /* White text */
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px; /* Highly rounded corners for a modern pill shape */
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: inline-block; /* To allow padding and margins to take effect properly */
}

/* You can continue to define styles for other elements like buttons, links etc. */

  