.expiry-overlay {
    height: 100vh; 
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
  }
  
  .expiry-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 85%;
    width: 400px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .expiry-title {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .expiry-intro {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .rating-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    padding: 0 2px;
    position: relative;
  }
  
  .rating-label {
    font-size: 0.95em;
    font-weight: bold;
    color: #C3202F;
  }
  
  .rating-label.middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .rating-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0;
  }
  
  .rating-button {
    flex: 1;
    min-width: 0;
    aspect-ratio: 1;
    border: 1px solid #FFFFFF;
    border-right: none;
    background-color: #C3202F;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    padding: 0;
    transition: all 0.2s ease;
  }
  
  .rating-button.selected {
    background-color: #000000;
    color: #FFFFFF;
  }
  
  .rating-button:last-child {
    border-right: 1px solid #FFFFFF;
  }