/* OBJECT PAGE CSS CODE - OVERALL */

.object-page-container {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

/* NAVIGATION BAR */

/* .navbar-object {
  width: 100%;
  height: 8.5%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 5;
} */

.full-screen-overlay { /* THIS IS TO START THE SILENT AUDIO WITH TRANSPARENT BUTTON*/
  position: fixed; /* Full-screen and stays in place during scrolling */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  z-index: 9999; 
  pointer-events: auto;
}

.nav2info-button {
  position: fixed;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  padding: 0;
  width: 12%;
  aspect-ratio: 1 / 1;  /*This good!*/
  border-radius: 50%;
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-left: 3%;
  margin-top: 2%;
  z-index: 50;
}

.info-icon {
  /* max-width: 70%; THESE THREE STYLES WERE FOR THE PERSONALISATION IMAGE, BOTTOM THREE ARE FOR FONTAWESOME ICONS
  height: auto;
  opacity: 0.8; */
  font-size: 2em; /* Adjust the size as needed */
  color: #ffffff;
  opacity: 0.8; /* Adjust transparency: 0 (transparent) to 1 (opaque) */
}

.nav2info-button:active {
  background-color: rgba(0, 0, 0, 1); /* Solid black background */
  border-color: rgba(255, 255, 255, 1); /* Solid white border */
}

.info-icon:active {
  opacity: 1; /* Fully opaque */
}

.nav2survey-button {
  position: fixed;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
  padding: 0;
  width: 12%;
  aspect-ratio: 1 / 1;  /*This good!*/
  border-radius: 50%;
  display: flex;
  align-items: center; 
  justify-content: center;
  left: 85%;
  margin-top: 2%;
  z-index: 50;
}

.survey-icon {
  font-size: 2em; /* Adjust the size as needed */
  color: #ffffff;
  opacity: 0.8; /* Adjust transparency: 0 (transparent) to 1 (opaque) */
}

.nav2survey-button:active {
  background-color: rgba(0, 0, 0, 1); /* Solid black background */
  border-color: rgba(255, 255, 255, 1); /* Solid white border */
}

.survey-icon:active {
  opacity: 1; /* Fully opaque */
}


/* .nav-button {
  padding: 10px 15px;
  background-color: blue; /
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
} */


/* SCROLLING CONTAINER */

.scroll-container {
  transition: transform 0.5s ease;
  z-index: 10;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-bottom;
  position: relative;
  overflow-y: auto;
}

.first-view {
  transform: translateY(0);
}

.second-view {
  transform: translateY(-65%); /* Moves the container up by the full viewport height */
}



/* VIDEOCAPTURE CSS CODE */

.video-container {
  position: relative; 
  height: 65%;
  overflow: hidden;
  transition: transform 2s ease-in-out;
  background-color: rgb(0, 0, 0);
  z-index: -10;
}
  
.video-element {
  transform-origin: center center; /* Ensures scaling happens from the center */
  display: block; /* Prevents inline default of video */
  width: 100%; /* Ensures video does not exceed the container width */
  height: auto; /* Maintains aspect ratio */
  transition: transform 0.3s ease; 
  pointer-events: none;  /* Prevents interaction with the video element */
  -webkit-user-select: none;  /* Prevents selection on iOS */
  user-select: none;  /* Standard property for compatibility */
  -webkit-tap-highlight-color: transparent;  /* Removes tap highlight on iOS */
}

.video-hidden {
  visibility: hidden;
}

.video-visible {
  visibility: visible;
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust color based on your background */
  font-size: 1.25em; /* Adjust font size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  width: 100%;
  height: 100%;
}

/* TESTING CSS */
/* .content-below {
  width: 100%; 
  display: block;
  z-index: -1000000;
}
.processed-image-container {
  position: absolute;
  top: 10px; 
  left: 10px; 
  z-index: -100000; 
  border: 2px solid white; 
  max-width: 40%; 
  max-height: 40vh; 
  overflow: hidden; 
}
.processed-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
} */


.zoom-buttons {
  position: absolute;
  bottom: 6%; /* Adjust as necessary to position it from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Centers the buttons horizontally */
  z-index: 10; /* Makes sure the buttons are above the video */
  display: flex;
  gap: 10px; /* Space between the buttons */
  cursor: pointer;
  color: white;
  align-items: center;
 
}

.zoom-buttons .active {
  background-color: #000000; /* or any highlight color */
  color: white;
  border-radius: 20%;
  width: 40px; 
  height: 30px; 
  font-weight: bold;
  font-size: 1.2em;
  display: flex; /* Added */
  align-items: center; /* Added */
  justify-content: center; /* Added */
}
.zoom-buttons .not-active {
  background-color: #c5c5c5; /* or any highlight color */
  color: rgb(0, 0, 0);
  border-radius: 20%;
  width: 40px; 
  height: 20px; 
  font-size: 1.1em;
  display: flex; /* Added */
  align-items: center; /* Added */
  justify-content: center; /* Added */
}

/* New styles for the loading scanner message */
.loading-scanner-message {
  text-align: center;
  font-weight: bold;
  font-size: 1.7em; /* Different font size */
  margin-bottom: 10px;
  z-index: 2;
  color: #000000; /* Different color for visibility */
  padding: 0 20px; /* Add some padding for better text wrapping */
  min-height: 40px; /* Add fixed height to prevent jumping */
}

/* .loading-scanner-image {
  width: 25%;  
  height: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0%;
  transition: all 0.3s ease;
}

.loading-scanner-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
}  */

.loading-scanner-subtitle {
  font-weight: normal;
  font-size: 1.3em;
  color: #666;
  margin-bottom: 15px;
}

.loading-scanner-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0%;
  margin-top: 25px; 
}

.loading-dots {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.loading-dots span {
  width: 12px;
  height: 12px;
  background-color: #C3202F;
  border-radius: 50%;
  animation: bounce 0.5s alternate infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.16s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.32s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-12px);
  }
}


/* OBJECTSUMMARY NAVIGATION */


.Object-summary-not-scanned {
  position: relative;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* NAVIGATION BUTTONS - COMMON STYLES */
.object-summary-navigation-down,
.object-summary-navigation-up {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.object-summary-navigation-down {
  height: 16%;
}

.object-summary-navigation-up {
  height: 14%;
}

/* TEXT STYLES */
.more-info-text,
.return-camera-text {
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  padding: 6px 16px;
  color: #C3202F !important;
  letter-spacing: 0.5px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.more-info-text:hover,
.return-camera-text:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* BUTTON STYLES - some of the return-to-camera styles are now redundent when using the camera icon */  
.more-info-button,
.return-camera-button {
  background: linear-gradient(145deg, #fccacab5, #c32030e2);
  border-radius: 50%;
  width: 35px; /* Increased size */
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  position: relative;
  bottom: 0px;
  box-shadow: 
    4px 4px 10px rgba(0, 0, 0, 0.3), /* Darker shadow with more spread */
    -4px -4px 10px rgba(255, 255, 255, 0.9); /* Light highlight */
  transition: all 0.3s ease;
  animation: pulseButton 2s ease-in-out;
}

.return-camera-button {
  margin-top: 8px; /* Adjust this value as needed */
}

.more-info-button:hover,
.return-camera-button:hover {
  background: linear-gradient(145deg, #e24d4d, #ffffff);
  transform: scale(1.05);
  box-shadow: 
    6px 6px 12px rgba(0, 0, 0, 0.15),
    -6px -6px 12px rgba(255, 255, 255, 1);
}

/* Active state for buttons */
.more-info-button:active,
.return-camera-button:active {
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  transform: scale(0.95);
  box-shadow: 
    inset 4px 4px 8px rgba(0, 0, 0, 0.1),
    inset -4px -4px 8px rgba(255, 255, 255, 0.9);
}

/* ICON STYLES */
.more-info-button svg,
.return-camera-button svg {
  font-size: 1.8em; /* Larger icons */
  color: #1a1a1a;
  animation: bounceArrow 1.5s infinite ease-in-out;
}

.camera-icon {
  width: 60%;
  height: auto;
  display: block;
  margin: auto;
}

/* ANIMATIONS */
@keyframes pulseButton {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes bounceArrow {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
}

.experimental-disclaimer {
  font-size: 0.7em;
  color: #34343497;
  font-style: italic;
  /* margin-bottom: 4px; */
  text-align: center;
}

/* 
BELOW IS ALL THE OLD NAVIGATION UP AND DOWN CSS CODE
.object-summary-navigation-down {
  height: 10%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.more-info-text {
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  font-size: 14;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 3px;
}

.more-info-button {
  background-color: rgb(240, 240, 240);
  border: solid;
  border-radius: 50%;
  width: 35px;
  height: 35px; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  position: relative;
  bottom: 0px;
}

.more-info-button svg {
  font-size: 1.5em;
  animation-delay: 3s;
  animation-iteration-count: 2; 
}

.object-summary-navigation-up {
  height: 10%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.return-camera-text {
  margin-bottom: 0px; 
  text-align: center;
  font-weight: bold;
  font-size: 14;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 3px;
}

.return-camera-button {
  background-color: rgb(240, 240, 240);
  border: solid;
  border-radius: 50%; 
  width: 35px; 
  height: 35px; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  position: relative;
  bottom: 0px;
}

.return-camera-button svg {
  font-size: 1.5em;
} */




/* NO OBJECT CSS */

/* 
.no-object-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 65%;
} */

.no-object-message {
  text-align: center;
  font-weight: bold;
  font-size: 1.4em;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin-bottom: 20px;
  z-index: 2;
}

.no-object-image.scan-figure {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4.5%; 
}

.no-object-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Style for the "Scanner not working" image */
.no-object-image.scanner-not-working {
  width: 80%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0%;
}



/* CAMERA NOT WORKING */

/* Add styles for the timeout message */
.timeout-message {
  text-align: center; /* Center align the text */
  color: white; /* Set text color to white */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for better visibility */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded corners */
  font-size: 1.3em; /* Increase font size */
}

/* Add styles for the error message */
.error-message {
  text-align: center; /* Center align the text */
  color: white; /* Set text color to white */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for better visibility */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded corners */
  font-size: 1.3em; /* Increase font size */
}

/* Style for the refresh button */
.refresh-button {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding for button */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1em; /* Font size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  margin-top: 30px; /* Space above the button */
}

/* Hover effect for the refresh button */
.refresh-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}



/* OBJECTSUMMARY CSS CODE */


.white-box {
  position: absolute; /* if necessary */
  bottom: 0;
  /* Other styling as needed */
  width: 100%;
  height: 1.8vh; /* 3% of the viewport height */
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 80px; /* Adjust as needed */
  border-top-right-radius: 80px; /* Adjust as needed */
}

.fade-effect {
  min-height: 64%;   /* Because of margin they dont look like up to 100% but i believe it is here */
  margin-bottom: 2%;
  position: relative;
  /* pointer-events: none; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

/* Add class for when two suggestions are present */
/* .fade-effect.with-suggestions {
  min-height: 63%; 
} */

/* .fade-effect.with-single-suggestion {
  min-height: 68%; 
} */

.gradient-top, .gradient-bottom {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 20px; /* adjust the height of the fade effect as needed */
  z-index: 2; /* above the chat content but below any fixed elements */
  pointer-events: none; /* allows interaction with the chat content */
  margin-top: -2px;
  padding-top: 0;
  margin-bottom: -1px;
}

.gradient-top {
  top: 0;
  background-image: linear-gradient(to bottom, #fff, rgba(255,255,255,0));
}

.gradient-bottom {
  bottom: 0;
  background-image: linear-gradient(to top, #fff, rgba(255,255,255,0));
}

.conversation-scroll {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  /* flex-shrink: 1; */
}

  
.object-summary-info {
  height: 47%;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
}

.object-summary-info.second-page {
  height: 42%;
}

.summary-text-container {
  flex-grow: 1; 
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  width: 65%;
}

.summary-text-container h2 {
  font-size: 1.1em; 
  margin-bottom: 1%;
  margin-right: 7%;
  margin-left: 0;
  margin-top: 0;
  z-index: 1000; /* Set a high z-index for this element */
  position: relative; /* Ensure the z-index takes effect */
}

.summary-text-container h3 {
  font-style: italic;
  font-weight: bold;
  font-size: 1em;
  margin-top: 0em;
  margin-bottom: 0em;
  color: #555;
}

.summary-text-container p {
  font-size: 1em;
  margin-top: 1%;
  margin-right: 7%;
  margin-left: 0;
  margin-bottom: 0;
}

.thumbnail-image-container {
  width: 30%;
  height: 100%;
  margin-right: 5%;
  position: relative;
}

.thumbnail-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 80%;
  margin-right: 0; /* Removed margin-right to align image to the left */
  object-fit: contain; 
  border-radius: 10px;
  box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.2);
}

.thumbnail-image-container img:hover {
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.3); 
}


/* RETURN TO CAMERA BUTTON */


/* SEPARATOR BETWEEN SECTIONS */
.section-separator {
  border-bottom: 1px solid #eee;
  margin: 0px 0;
  width: 70%;
  margin-left: 15%;
  margin-bottom: 5px;
}

/* OBJECTCONVERSATION CSS CODE */

/* .object-conversation-container {
  height: 65%; 
  overflow-y: auto; 
 
  position: relative;
} */

.chat-container {
  position: relative;
  height: 68%;
}

.message {
  position: relative;
  margin: 10px;
  min-height: 4.5vh;
}

.user {
  position: relative;
  /* justify-content: flex-start; */
}

.assistant {
  position: relative;
  /* justify-content: flex-start; */
}

.message-photo {
  position: absolute;
  top: 0;
  left: 1.5%;
  width: 4.5vh;
  height: 4.5vh;
  background-color: transparent;
  border-radius: 50%; /* Changed from 20px to 50% for perfect circle */
  overflow: hidden;
}

/* Remove or comment out the default version */
/* .assistant .message-photo {
    background-image: url('/public/AssistantPhoto.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} */

/* Add explicit classes for both MT and RLM */
.assistant.mt .message-photo {
  background-image: url('/public/AssistantPhoto.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.assistant.rlm .message-photo {
  background-image: url('/public/AssistantPhoto-rlm.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.user .message-photo {
  background-image: url('/public/UserPhoto.png');
  background-size: 72%; /* Changed from cover to contain */
  background-repeat: no-repeat;
  background-position: center;
}

.message-sender {
  display: block;
  padding-top: 0.8vh;
  padding-left: 13.5%;
  font-size: 1rem;
  color: #666;
  font-weight: bold;
}

.message-text {
  position: block;
  padding-top: 0.5vh;
  padding-left: 13.5%;
  padding-right: 2%;
  /* max-width: 60%; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  /* background-color: #f0f0f0; */
}

.message-text.thinking {
  font-style: italic;
}

.message-text.transcribing {
  font-style: italic;
}


/* Add BELOW to your existing CSS, THIS IS FOR ERROR MESSAGES */
.message-text.error {
  font-style: italic;
  text-align: center;
  color: #666;
  padding: 10px 20px;
  /* width: 100%; 
  font-size: 0.9em; */
}
/* Add ABOVE to your existing CSS, THIS IS FOR ERROR MESSAGES */


.audio-toggle-button{
  margin-left: 2vh;
  width: 3vh; 
  height: 3vh;
  border-radius: 50%; /* Circular shape */
  background-color: rgb(242, 242, 242); 
  padding: 0;
  border: none; /* No border */
  cursor: pointer;
} 

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%; 
  height: 100%;
  border-radius: 50%;
}

.loading-container.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  border: 1.5px solid transparent;
  border-top-color: #000000; 
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.replay-button {
  margin-left: 1vh;
  width: 3vh; 
  height: 3vh;
  border-radius: 50%; /* Circular shape */
  background-color: rgb(242, 242, 242);
  padding: 0;
  border: none; /* No border */
  cursor: pointer;
}

/* NOT A VERY PERMANENT SOLUTION USING ADJUST SIZE CONTAINERS - BECAUSE OF LOADING */
.adjust-size-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%; 
  height: 100%;
  border-radius: 50%;
}


/* USER INPUT AREA */

.chat-input-area {
  height: 8%;
  background-color: white; /* Or any color as per your design */
  display: flex;
  padding-left: 5%; 
  padding-right: 5%; 
  flex-direction: column;
}

.chat-input-area input{
  flex-grow: 1;
  font-size: 16px; /* Prevent zoom on mobile devices */
  border: 1px solid #8c8c8c;
  border-radius: 15px;
  /* padding: 10px 15px;  */
  padding-left: 13px;
  padding-right: 13px;
  height: 25px;
  width: 76%;
  outline: none; /* REMOVE THIS AT SOME STAGE, ITS THE ACCESSABILITY BLUE BORDER FEATURE, BUT IT WAS CUT OFF SO LOOKED BAD */
}

.chat-input-area button {
  font-size: 16px; /* Prevent zoom on mobile devices */
  margin-left: 4%;
 
}

.mic-button {
  width: 4.5vh;
  height: 4.5vh;
  border: none;
  border-radius: 50%;
  background-color: #000000;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-left: 8%;
  padding: 0;
}

/* Add this new style for disabled state */
.mic-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.disclaimer-text {
  font-size: 0.63em; /* Smaller font size */
  color: #666; /* Light grey color for subtlety */
  font-style: italic;
  text-align: center;
  padding: 0px 0px; /* Adds some padding around the text */
  padding-top: 0; /* Removes additional space above the text */
  margin-top: 2px;  /* Ensures there is no extra margin pushing the text down */
}

.listening-indicator {
  margin-top: 10px;
  color: #007bff;
  font-style: italic;
}

.mic-button:disabled {
  /* Add your desired styles for the disabled state here */
  background-color: #cccccc; /* Example: a grey background */
  color: #666666; /* Example: darker text color */
  cursor: not-allowed; /* Shows a 'not allowed' cursor */
}


/* RECORDING BOX */
.stop-recording-box {
  width: 100%;
  min-height: 25vh;
  background-color: #f2f2f2; /* Adjust color as needed */
  position: relative;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
  cursor: pointer;
  
}

.timer {
  position: absolute;
  top: 8%;
  left: 5%;
  color: #007bff;
}

.listening-animation-container {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 80%; /* Adjust this value as needed */
  width: auto; /* Adjust this value as needed */
  left: 50%;
  /* Add more styling as needed */
}

.stop-recording-text {
  color: #007bff;
  position: absolute;
  bottom: 15%;
  margin: 0;
  padding: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.cancel-recording-button {
  position: absolute;
  top: 8%; /* Adjust as needed */
  right: 3.5%; /* Adjust as needed */
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: .7em;
}


.validation-button {
  padding: 0px 0px;
  border: none;
  border-radius: 0px;
  font-size: 15px;
  margin-right: 3%; /* pushes the button or text to the right */
  float: right;
  border-radius: 10%;
}

.validated {
  background-color: #508c53; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 12px;
  font-size: 10.5px;
}

.validated:hover {
  background-color: #2d4d2f;
}

.not-validated {
  color: rgb(180, 66, 66);
  background: none;
  border: none;
  cursor: default;
  display: inline;
  font-size: 11px;
}
/* 
BELIEVE ALL OF THE BELOW IS THE OLD CODE FOR THE VALIDATION POPUP
.validation-modal {
  position: fixed;
  width: 80%;
  max-height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  z-index: 1000;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 25px;
  border: none;
  background: transparent;
  color: rgb(255, 0, 0);
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
} */

h4 {
  margin: 0;
  padding-top: 5px; /* Minimal top padding for the header */
  padding-right: 40px; /* Right padding to prevent overlap by the close button */
}

.validation-details {
  white-space: pre-wrap;
  line-height: 1.35;
  margin-top: 20px; /* space below the header */
}



/* Update suggested questions styling */
.suggested-questions-container {
  flex-shrink: 0;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0px 22px 0px;
  display: flex;
  flex-direction: column;
  height: 13%; /* Default height for 2+ questions */
  min-height: unset; /* Remove min-height to allow height to take precedence */
  overflow-y: auto;
  gap: 10%;
}

/* This will override the height for single question */
/* .suggested-questions-container.single-question {
  height: 8%; 
} */

.suggested-question-button {
  background-color: #000000c2;
  color: white;
  border: none;
  border-radius: 12px;
  height: 35%; /* Set height relative to container, accounting for gap */
  max-height: 35px; /* Prevent buttons from getting too tall */
  width: 100%;
  padding: 0 15px; /* Remove vertical padding, keep horizontal */
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
  /* Text sizing */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(0.8em, 2.3vh, 0.9em); /* Slightly smaller text */
  line-height: 1.2;
}

/* Increased height for single question */
/* .suggested-questions-container.single-question .suggested-question-button {
  height: 60%; 
} */

/* Add send icon */
.suggested-question-button::after {
  content: '';
  background-image: url('/public/Send.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  opacity: 0.8; /* Slightly subtle */
}

/* Set gap as a percentage */
/* .suggested-questions-container > .suggested-question-button + .suggested-question-button {
  margin-top: 4%;
} */

.suggested-question-button:hover {
  background-color: #000000;
}

.suggested-question-button:active {
  background-color: #34343497;
  transform: translateY(1px);
}


/* THE BELOW CODE IS FOR RESTRICTING DESKTOP ACCESS */
.desktop-message {
  display: none;  /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Show desktop message when aspect ratio is wider than 1:1 (square) */
@media screen and (min-aspect-ratio: 1/1) {
  .object-page-container {
    display: none;
  }
  
  .desktop-message {
    display: flex;
  }
}
/* THE ABOVE CODE IS FOR RESTRICTING DESKTOP ACCESS */



/* CONSENT BUTTON CSS */
.consent-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.consent-button {
  background-color: #C3202F;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.consent-button:hover {
  background-color: #f5f5f5;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.consent-button .arrow {
  font-size: 18px;
  margin-left: 4px;
}

.consent-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #cccccc;
}



/* NEW LANGIAGE BUTTON CSS */
/* Add these with your other nav button styles */
.nav2language-button {
    position: fixed;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: rgba(255, 255, 255, 0.4);
    padding: 0;
    width: 12%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-top: 2%;
    left: 44%;  /* Adjust this to align with other buttons */
    z-index: 50;
    overflow: hidden;  /* Ensure image doesn't spill out */
}

.language-icon {
    width: 100%;  /* Fill the entire button */
    height: 100%;
    object-fit: cover;  /* Ensure image covers the space */
    opacity: 0.8;  /* Match other icons' transparency */
}

.nav2language-button:active {
    background-color: rgba(0, 0, 0, 1);
    border-color: rgba(255, 255, 255, 1);
}

.language-icon:active {
    opacity: 1;
}

.language-change-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.95);
    color: #333;
    padding: 15px 25px;  /* Reduced horizontal padding */
    border-radius: 15px;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 240px;  /* Reduced minimum width */
    max-width: 70%;    /* Reduced maximum width */
    animation: slideInFadeOut 3s ease-in-out forwards;
}

.language-change-popup p {
    margin: 6px 0;
    font-size: 15px;
    line-height: 1.3;
}

.language-change-popup p:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 6px;
    margin-bottom: 6px;
}

.language-change-popup p:last-child {
    font-style: italic;
}

@keyframes slideInFadeOut {
    0% {
        opacity: 0;
        transform: translate(-50%, -40%);
    }
    15% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    85% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%);
    }
}

/* TELL ME MORE BUTTON CSS */
/* Add this with your other chat-related styles */
.tell-me-more-container {
  display: flex;
  justify-content: center;
  padding: 1px 0;
  /* margin: 5px 0; */
}

.tell-me-more-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.95em;
  color: #333;
  transition: all 0.3s ease;
  box-shadow: 
    2px 2px 5px rgba(0, 0, 0, 0.1),
    -2px -2px 5px rgba(255, 255, 255, 0.9);
}

.tell-me-more-button:hover {
  background-color: #000000;
  color: white;
  transform: translateY(-1px);
  box-shadow: 
    3px 3px 8px rgba(0, 0, 0, 0.15),
    -3px -3px 8px rgba(255, 255, 255, 1);
}

.tell-me-more-button:active {
  background-color: #34343497;
  transform: translateY(1px);
  box-shadow: 
    inset 2px 2px 5px rgba(0, 0, 0, 0.1),
    inset -2px -2px 5px rgba(255, 255, 255, 0.9);
}

.tell-me-more-button .plus-icon {
  font-size: 0.8em;
}

.tell-me-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}
