.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    max-width: 400px;
    position: relative;
    max-height: 60vh;
    overflow-y: auto;
  }
  
  .popup-title {
    margin: 0 0 20px 0;
    font-size: 22px;
    font-weight: bold;
  }
  
  .popup-text {
    margin: 15px 0;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
  }
  
  .popup-button {
    padding: 12px 24px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    flex: 1;  /* Added to make buttons fill available space equally */
  }
  
  .popup-button.primary {
    background-color: #C3202F;
    color: white;
  }
  
  .popup-button.primary:hover {
    background-color: #8e1925;
  }
  
  .popup-button.secondary {
    background-color: #6c757d;
    color: white;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }
  
  /* Consent popup specific styles */
  .consent-content {
    max-width: 500px;
    /* padding: 30px; */
  }
  
  .language-selection {
    margin-bottom: 20px;
  }
  
  .language-select {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .loading-message {
    font-size: 18px;
    color: #333;
    text-align: center;
  }


  /* FOR THE VALIDATION POPUP */

  .popup-subtitle {
    color: #666;
    font-size: 15px;
    line-height: 1.4;
    margin: 0 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .validation-sources {
    margin: 0;
  }
  
  .source-item {
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 15px;
  }
  
  .source-item strong {
    color: #2c3e50;
    margin-right: 5px;
  }

  .aiinfo-title {
    padding-top: 0px;  /* Add extra padding at the top to prevent title/X button overlap */
    width: 90%;
  }

  
  /* CONSENT POPUP SPECIFIC CSS */
  .consent-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65%;
    background-color: #7F0E29;
    z-index: 5;
    display: flex;
    align-items: center;
  }
  
  .consent-content {
    width: 100%;
    max-height: 100%;  /* Ensure it doesn't exceed overlay height */
    overflow-y: auto;  /* Enable scrolling */
    /* Remove padding from here */
  }
  
  /* Add a new inner wrapper for the content */
  .consent-content-inner {
    padding: 20px;
    color: white;
    min-height: 100%;  /* This ensures short content is centered */
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centers content vertically */
  }
  
  .consent-content h2 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #EAB952;
    font-size: 1.35em;
  }
  .consent-text {
    font-size: 1.1em;
    line-height: 1.4;
  }
  
  .consent-text ul {
    padding-left: 20px;
    margin: 15px 0;
  }
  
  .consent-text strong {
    color: #EAB952;
  }
  
  .consent-text a {
    color: inherit;
    text-decoration: underline;
  }

  .consent-text p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  /* Remove old popup styles that aren't needed */
  /* .popup-overlay, .popup-content, etc. can be removed */

  /* @media screen and (max-aspect-ratio: 1/1.5) {
    .consent-overlay {
      max-height: 70vh;
    }
  } */



  
  /* SURVEY POPUP SPECIFIC CSS */
  .survey-question {
    margin-bottom: 25px;
  }

  .survey-question p {
    font-size: 1em;
    margin-bottom: 7px;
  }

  .rating-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }

  .rating-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  .rating-label {
    font-size: 1em;
    color: #C3202F;
  }

  .rating-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .rating-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgba(195, 32, 47, 0.3); /* Lighter version of #C3202F */
    color: #000000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    font-weight: bold;
  }

  .rating-button.selected {
    background-color: #C3202F;
    color: #ffffff;
  }

  .feedback-textarea {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    margin-top: 8px;
    font-family: inherit;
    font-size: 16px;
    line-height: 1.4;
    box-sizing: border-box;
  }

  /* Prevent zoom on focus for iOS devices */
  @media screen and (max-width: 768px) {
    .feedback-textarea {
      font-size: 16px !important;
    }
  }

  .submit-button {
    width: 100%;
    margin-top: 5px;
  }

  .thank-you-message {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .popup-subtitle a {
    color: #0066cc;
    text-decoration: underline;
  }

  .popup-subtitle a:hover {
    color: #004499;
  }




  /* LANGUAGE SELECTOR SPECIFIC CSS */
  .language-selector {
    text-align: center;
    padding: 20px;
  }
  
  .language-selector h2 {
    color: #EAB952;  /* Gold color */
    font-size: 1.3em;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  
  .language-instruction {
    color: white;
    font-size: 1.3em;
    line-height: 1.5;
    margin-bottom: 40px;
  }
  
  .language-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .language-button {
    width: 100%;
    padding: 8px 20px;
    background: white;
    border: 2px solid #ddd;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.15s ease;
    position: relative;
    top: 0;
  }
  
  .language-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-color: #bbb;
  }
  
  .language-button:active {
    transform: scale(0.98);
    top: 2px;
    background-color: #f0f0f0;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    border-color: #999;
  }
  
  .language-button.selected {
    background: #f0f0f0;
    border-color: #007AFF;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .flag {
    font-size: 1.8em;
  }
  
  .language-text {
    font-size: 1.4em;
    color: #333;
  }
  
  .language-button.selected {
    background: #f0f0f0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .continue-button {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 10px;
    background: #007AFF;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .continue-button.disabled {
    background: #cccccc;
    cursor: not-allowed;
  }

  .disabled-overlay {
    pointer-events: none;
    opacity: 0.5;
  }

  .flag-image {
    width: 30px;  /* Set fixed width */
    height: 30px;  /* Set fixed height */
    object-fit: contain;  /* Maintain aspect ratio */
    display: block;  /* Remove any extra spacing */
  }
  
  .button-content {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px 10px;
  }

  

  /* NPS-specific styles */
  .nps-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 15px 10px;
    margin: 0 auto;
  }

  .nps-question {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #f7d68f;
  }

  .nps-rating-container {
    width: 100%;
  }

  .nps-rating-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    padding: 0 2px;
  }

  .nps-rating-label {
    font-size: 0.95em;
    font-weight: bold;
    color: #FFFFFF;
  }

  .nps-rating-buttons {
    display: flex;
    width: 100%;
    justify-content: center; /* Center the buttons */
    gap: 0; /* Remove any gap */
  }

  .nps-rating-button {
    flex: 1; /* Each button takes equal space */
    min-width: 0; /* Allow buttons to shrink below content size */
    aspect-ratio: 1;
    border: 1px solid #C3202F;
    border-right: none;
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    padding: 0;
  }

  .nps-rating-button:last-child {
    border-right: 1px solid #C3202F;
  }

  .nps-rating-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  .nps-rating-label {
    font-size: 0.95em;
    font-weight: bold;
    color: #FFFFFF;
  }

  /* NPS overlay specific */
  .nps-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
  }

  .nps-content {
    background-color: #C3202F;
    border-radius: 15px;
    width: 92%;
    max-width: 450px;
    position: relative;
    cursor: default;
    z-index: 10000;
  }